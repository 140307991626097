import React from "react";
import zia from '../assets/images/zia-tutorial.svg';
import '../assets/styles/chat.css';

const LoadingChat = ({agent, text, options}) => {

    return (    
        <>
            <div className="SingleChat">
                <div className="p-4 flex items-center gap-3">
                    <div className="w-[10%]">
                        <img className="object-cover rounded-full overflow-hidden h-12" src={zia} /> 
                    </div>
                    <div className="w-[80%]">
                        <p className="text-sm px-2">
                        <svg id="dots" width="40px" height="16px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="dots" fill="#A3A3A3">
                                    <circle id="dot1" cx="25" cy="30" r="13"></circle>
                                    <circle id="dot2" cx="65" cy="30" r="13"></circle>
                                    <circle id="dot3" cx="105" cy="30" r="13"></circle>
                                </g>
                            </g>
                        </svg>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoadingChat