import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
import {chatMock, chatMockSingle} from '../utils/mocks';
import { useLS } from '../contexts/LsContext.js';
import { GptAPI } from "../modules/gptAPI.js";
import { useItinerary } from "./itineraryContext.js";

  
  export const chatContext = createContext();
  
  const useProvideChat = () => {
    const [chatList, setChatList] = useState(chatMockSingle);
    const [simpleLoading, setSimpleLoading] = useState(false);

    const itineraryData = useItinerary() || {};
    const {scheduledDays, setScheduledDays} = itineraryData;

    const LSData = useLS() || {};
    const {setToLS, isInLS} = LSData;

    const copyChatAndSaveToLS = async (payload) => {
      const copyOfChatList = [...chatList];
      copyOfChatList.push(payload)
      setChatList(copyOfChatList);
      await setToLS('chatLS', copyOfChatList);
    }

    const updateChat = async (payload) => {
      await copyChatAndSaveToLS(payload);

      if (payload?.agent === 'user') {
        setSimpleLoading(true);

        await setTimeout(() => {
          setSimpleLoading(false);
        }, 1000);
        
        // const botSimpleResponse = await GptAPI.sendSimpleChatData(payload?.text);
        // copyChatAndSaveToLS(botSimpleResponse);
        // if (botSimpleResponse?.loading) {
          // const botComplexResponse = await GptAPI.sendComplexChatData(payload?.text, scheduledDays: scheduledDays);
          // await copyChatAndSaveToLS(botComplexResponse);
        // }
        // setSimpleLoading(false);
      }
    }

    useEffect(() => {
      const setChatOnLoad = async () => {
        const chatInLs = await isInLS('chatLS');
        if (chatInLs) {
          const formattedChat = await JSON.parse(chatInLs);
          setChatList(formattedChat)
          return
        }
      }
      setChatOnLoad();
    }, [])

    return {
        chatList,
        updateChat, 
        simpleLoading
    };
  };
  
  export const ChatProvider = ({ children }) => {
    const chat = useProvideChat();
    return (
      <chatContext.Provider value={chat}>{children}</chatContext.Provider>
    );
  };
  
  export const useChat = () => useContext(chatContext);
  