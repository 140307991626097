import React from "react";
import zia from '../assets/images/zia-tutorial.svg'
import tav from '../assets/images/avatar.svg'
import clsx from "clsx";
import cut from '../assets/images/cutleries.svg'
import { useLayout } from "../contexts/layoutContext";
import { OPTIONS } from "../utils/CONSTANTS";

const SingleChat = ({agent, text, options}) => {

    const layoutData = useLayout() || {};
    const {setPlaceDetail} = layoutData;

    return (    
        <>
            <div className={clsx(agent !== 'bot' && 'bg-white border-b-[1px] border-b-light-grey', "SingleChat")}>
                <div className="p-4 flex items-center gap-3">
                    <div className="w-[10%]">
                        {agent === 'bot' && <img className="object-cover rounded-full overflow-hidden h-12" src={zia} />} 
                    </div>
                    <div className={clsx("w-[80%]")}>
                        <p className={clsx(agent === 'bot' ? 'text-left' : 'text-right', "text-sm px-2")}>{text}</p>
                    </div>
                    <div className="w-[10%]">
                        {agent !== 'bot' && <img className="rounded-full overflow-hidden h-12" src={tav} />} 
                    </div>
                </div>
                {options && options.length>0 && (
                    <div className="grid grid-cols-2 gap-3 px-4 pb-4">
                        {options.map((el) => (
                            <div className="w-full">
                                <div className={clsx(`w-full rounded-xl p-2 bg-${OPTIONS[el?.type]?.color}`)}>
                                    <img src={OPTIONS[el?.type]?.icon}  className="block mx-auto my-2 w-8"/>
                                    <div className='text text-center'>
                                        <p className='text-xs text-white'>{el?.name}</p>
                                        <p className='text-[10px] opacity-70 text-white'>{el?.location}</p>
                                    </div>
                                </div>
                                <button className={`w-full block rounded-full bg-white border-[1px] border-${OPTIONS[el?.type]?.color} text-center my-3 text-sm py-1`} onClick={() => setPlaceDetail(el?.placeId)}>Informazioni</button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default SingleChat